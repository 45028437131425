import { Grid, Image, Text, Title } from '@mantine/core';
import React, { useState } from 'react';
import productionIntro from '../videos/production-intro.mp4';
import factory from '../videos/factory.mp4';
import pollution from '../images/pollution.png';
import explosion from '../images/explosion.png';
import highCost from '../images/high-cost.png';
import pollutionInverse from '../images/pollution-inverse.png';
import explosionInverse from '../images/explosion-inverse.png';
import highCostInverse from '../images/high-cost-inverse.png';
import step1 from '../images/step1.svg';
import step2 from '../images/step2.svg';
import step3 from '../images/step3.svg';
import step4 from '../images/step4.svg';
import step5 from '../images/step5.jpg';
import stack from '../images/stack.png';
import productionLine from '../images/production-device.jpg';
import noStore from '../images/no-store.svg';
import safe from '../images/safe.svg';
import greenTech from '../images/green-tech.svg';
import lowerCost from '../images/lower-cost.svg';
import modular from '../images/modular.svg';
import './product.less';
import { motion } from 'framer-motion';
import SEO from "../components/seo";

const dangers = [
  { name: '高污染', image: pollution, imageInverse: pollutionInverse },
  { name: '爆炸风险', image: explosion, imageInverse: explosionInverse },
  { name: '高成本', image: highCost, imageInverse: highCostInverse }
];

const steps = [
  { name: '氢化反应', image: step1, width: '19%' },
  { name: '氧化反应', image: step2, width: '19%' },
  { name: '萃取工序', image: step3, width: '19%' },
  { name: '萃余及净化工序', image: step4, width: '24%' },
  { name: '运输至客户', image: step5, width: '19%' },
];

const advantages = [
  { icon: noStore, description: '水、电、氧现产现用', tag: '无需储存' },
  { icon: safe, description: '<6%浓度可调', tag: '100%安全' },
  { icon: greenTech, description: '无副产品', tag: '绿色技术' },
  { icon: lowerCost, description: '20-40%', tag: '成本降低' },
  { icon: modular, description: '轻松增减产能', tag: '模块化生产' },
];

function Product() {
  const [play, setPlay] = useState<boolean>(false);

  return (<div className="product">
    <section className="bg">
      <video src={factory} autoPlay loop muted></video>
      <div className='text-tag'>基于新型电化学技术的双氧水现场生产设备，我们改变了传统双氧水的生产与使用方式！</div>
    </section>
    <section className="production-process">
      <Title className='title' order={1}>传统双氧水是怎么生产出来的</Title>
      <Title className='sub-title' order={2}>蒽醌法化工合成</Title>
      <div className='list'>
        {dangers.map(danger => (
          <motion.div
            key={danger.name}
            whileHover={{
              backgroundColor: '#37acbf',
              color: '#fff'
            }}
            className="item"
          >
            <img className='image-normal' src={danger.image} />
            <img className='image-inverse' src={danger.imageInverse} />
            <span>{danger.name}</span>
          </motion.div>
        ))}
      </div>
      <Text className='image-description'>有机相下的多步反应</Text>
      <div className="steps-container">
        {steps.map(({ name, image, width }, index) => (
          <div key={index} className="step-container" style={{ width }}>
            {index === 4 && <Text className="step5-description">27.5%、50%<br />高浓度运输</Text>}
            <img src={image} />
            <Text className="step-name">{name}</Text>
          </div>
        ))}
      </div>
    </section>
    <section className="production-device">
      <Title id="tech" className='title' order={1}>橙氧科技先进的电堆技术，让现场生产终于得以实现</Title>
      <Text className='description'>双氧水现场生产技术已研究了数十年，但较差的电化学性能使生产成本居高不下、导致了商业化失败。我们在电催化、电极设计与制造、电堆流场等方面均取得了巨大进步，实现了双氧水高效电解合成，成本比传统蒽醌工艺更具竞争力</Text>
      <div style={{ textAlign: 'center' }}>
        <motion.img
          className='stack'
          src={stack}
          whileHover={{ scale: 1.2 }}
          transition={{ duration: 3 }}
        />
      </div>
    </section>
    <section className="production-device-intro">
      <Title className='title' order={1}>橙氧科技双氧水现场生产设备介绍</Title>
      <div className='video-container' onClick={() => setPlay(true)}>
        <video src={productionIntro} preload="metadata"></video>
      </div>
    </section>
    {play && <div className="video-mask">
      <div className="video-container">
        <video src={productionIntro} autoPlay controls></video>
        <span onClick={() => setPlay(false)} className="close-btn"></span>
      </div>
    </div>}
    <section className="production-line">
      <div className="title-container">
        <Title className='sub-title' order={2}>橙氧科技电化学现场生产双氧水设备</Title>
        <Title className='title' order={1}>灵活嵌入客户生产线中</Title>
      </div>
      <motion.img
        src={productionLine}
        whileHover={{ scale: 1.1 }}
        transition={{ duration: 3 }}
      />
    </section>
    <section className="advantages">
      <Title className='title' order={1}>带来五大优势</Title>
      <div className="list">
        {advantages.map(advantage => (
          <div key={advantage.tag} className='item'>
            <img src={advantage.icon} />
            <Text className='description'>{advantage.description}</Text>
            <Text className='tag'>{advantage.tag}</Text>
          </div>
        ))}
      </div>
    </section>
  </div>);
}

export default Product;

export const Head = () => (<SEO />)
